import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import partnerData from '../data/partners.json'
import React from "react"

const Partners = () => {
  const { partnerImages } = useStaticQuery(graphql`
    query {
      partnerImages: allFile(filter: { relativeDirectory: { eq: "partners" } }) {
        edges {
          node {
            childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  quality: 95
                )
            }
            name
          }
        }
      }
    }
  `)
  
  return (
    <div className="bg-gray-100">
      <div className="max-w-screen-xl mx-auto py-10 px-2 sm:px-4 text-gray-900 text-center">
        <h1 className="text-2xl tracking-tight leading-10 sm:leading-none font-extrabold text-gray-900 sm:text-3xl lg:text-4xl xl:text-5xl mb-4 sm:mb-8">
          Our <span className='text-blue-700'>Sponsors</span>
        </h1>
        <p className='max-w-3xl mx-auto mb-6 text-gray-500'>The International Biology Bowl is proudly sponsored by {partnerData.map(({ name }, i) => {
          return (
            <span>
              {(i === partnerData.length-1 && partnerData.length > 1) && "and "}
              <strong>{name}</strong>
              {i !== partnerData.length-1 && ", "}
            </span>
          )
        })}!</p>
        <div className="m-auto flex flex-wrap overflow-hidden align-center justify-center items-center sm:max-w-3xl">
          {partnerData.map(partner => {
            return (
              <div className="my-3 px-3 w-full overflow-hidden sm:w-1/3">
                <a href={partner.link} target="_blank">
                  <GatsbyImage
                    className="partner-img"
                    image={getImage(
                      partnerImages.edges.find(
                        x => x.node.name === partner.src
                      ).node.childImageSharp.gatsbyImageData
                    )}
                    alt={partner.name}
                    style={{width: "100%", height: "auto"}}
                  />
                </a>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Partners